import Gmodal from 'glory-modal';
import {requestQuoteModal} from './requestQuoteModal';

export const quickViewModal = (product_id = null) => {

    if (product_id && typeof product_id === 'number') {
        quickView(product_id);
        return;
    }

    let quickViewLinks = document.querySelectorAll("a.quick-view");

    if (!quickViewLinks.length) {
        return;
    }

    if (product_id !== 'number') {

        quickViewLinks.forEach(function (qvlink, index) {
            qvlink.addEventListener('click', function (event) {
                event.preventDefault();
                let product_id = qvlink.dataset.productId;
                quickView(product_id);
            });
        });

    }

    function quickView(product_id) {

        const url = '/products/quickview/'+product_id;
        const modalbody = document.querySelector('#quick-view-modal .gmodal__body');

        let selector = '#quick-view-modal';
        let config = {
            stickySelectors: [],
            animation: false,
            backdrop: true,
            closeBackdrop: true,
            keyboard: true
        }

        let modal = new Gmodal(selector, config);
        var elem = document.querySelector(selector);

        fetch(url).then(function (response) {

            return response.text();

        }).then(function (html) {

            modalbody.innerHTML = html;
            modal.open();
            requestQuoteModal(modal);

        }).catch(function (err) {

            console.warn('Something went wrong.', err);

        });

        
        elem.addEventListener('gmodal:close', function(evt) {

            const quickviewContent = document.getElementById("quick-view-content");

            if (quickviewContent) {
                quickviewContent.remove();
            }

        });

    }

}