import Gmodal from 'glory-modal';

export const requestQuoteModal = (otherModal = null) => {

    let requestQuoteLinks = document.querySelectorAll("a.request-quote");

    if (!requestQuoteLinks.length) {
        return;
    }

    requestQuoteLinks.forEach(function (rqLink, index) {
        rqLink.addEventListener('click', function (event) {
            event.preventDefault();
            let entryId = rqLink.dataset.entryId;
            requestQuoteModal(entryId);
        });
    });

    function requestQuoteModal(entryId) {

        const url = '/request-quote/'+entryId;
        const modalbody = document.querySelector('#request-quote-modal .gmodal__body');

        let selector = '#request-quote-modal';
        let config = {
            stickySelectors: [],
            animation: false,
            backdrop: true,
            closeBackdrop: false,
            keyboard: false
        }

        let modal = new Gmodal(selector, config);
        var elem = document.querySelector(selector);



        fetch(url).then(function (response) {

            return response.text();

        }).then(function (html) {

            if (otherModal && otherModal._isOpen) {
                otherModal.close();
            }

            modalbody.innerHTML = html;
            modal.open();

            const requestQuoteForm = document.querySelector('#request-quote-form form');

            requestQuoteForm.addEventListener("freeform-ready", function (event) {
                const freeform = event.freeform;
                const form = event.form;
                event.options.ajax = true;
                event.options.errorClassList = 'text-red-500 text-xs';
                event.options.loadingText = "Loading...";
            });

            requestQuoteForm.addEventListener("freeform-ajax-success", function (event) {
                setTimeout(function () {
                    modal.close();
                }, 2000);
            });

            // requestQuoteForm.addEventListener("freeform-on-submit", function (event) {
            //     event.preventDefault();
            // });

        }).catch(function (err) {
            console.warn('Something went wrong.', err);
        });

        
        elem.addEventListener('gmodal:close', function(evt) {

            const modalContent = document.getElementById("request-quote-content");
            if (modalContent) {
                modalContent.remove();
            }

        });

    }

}